@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

body {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: #eee;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container {
  max-width: 1200px;
  margin: auto;
}

#form {
  background: #fff;
  max-width: 500px;
  max-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 3px 3px 40px #545454e8;
  position: relative;
}

#form input,
#form select {
  margin: 1rem 0;
  padding: 0.75rem 0.25rem;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  width: 100%;
}

#form header {
  padding-bottom: 40px;
}

#form h4 {
  color: #555;
  font-size: 0.9rem;
}

.mt {
  margin-top: 2rem;
}
#form .small {
  font-size: 0.7rem;
  color: #555;
}

#form .checkbox-label {
  display: inline-block;
  cursor: pointer;
  font-size: 0.9rem;

}

#form .checkbox-input {
  /*appearance: none;*/
  transform: scale(120%);
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid gray;
  margin-right: 8px;
  vertical-align: middle;

}

#form .checkbox-input:checked {
  background-color: gray;
}

#form .input-container {
  position: relative;
  font-size: 1rem;
  color: #333;
}

#form .input-grp {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

#form button {
  width: 100%;
  padding: 1rem 0.5rem;
  margin-top: 1.5rem;
  outline: none;
  border: none;
  border-radius: 7px;
  background-color: #fd8641;
  color: #fff;
  letter-spacing: 1px;
  font-size: 1.25rem;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

#form button:hover:before {
  width: 100%;
  transition: 0.2s all;
}
#form button:hover {
  background-color: #fd701e;
}
#form button:focus {
}

#form button:active {
  background-color: red;
}

#card {
  height: 200px;
  width: 100%;
  max-width: 385px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center;
  color: #eee;
  filter: drop-shadow(1px 3px 10px #222);

  animation: fadeIn 0.2s linear 1;
}

@keyframes fadeIn {
  from {
    top: -150px;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#card .header {
  display: flex;
  justify-content: space-between;
  height: 70px;
}

#card .sticker {
  width: 50px;
  height: 40px;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  border-radius: 5px;
}

#card .logo {
  max-width: 60px;
}

#card .body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60%;
}

#card .footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}
